import { Capacitor } from "@capacitor/core";
import { isNullOrUndefined } from "@clipboard-health/util-ts";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { getUserCurrentLocation } from "@src/appV2/Location/providers/hypertrackLocationProvider";
import { type Shift } from "@src/lib/interface/src/lib/shift";
import { RadarTrackCallback } from "capacitor-radar";
import HyperTrack from "hypertrack-sdk-ionic-capacitor";
import { pick } from "lodash";

type Coordinates = [number, number] | undefined;

interface GeofenceStatusParams {
  shift: Shift;
  isHyperTrackEnabledForClockInOut: boolean;
  checkFacilityGeofence: () => Promise<{
    isInsideGeofence: boolean;
    isSuccess: boolean;
    radarLocation: RadarTrackCallback | undefined;
  }>;
}

interface GeofenceStatusResult {
  isLocationExperienceEnabled: boolean;
  isOutsideFacilityGeofence: boolean;
  location: Coordinates;
}

export async function getGeofenceStatus({
  shift,
  isHyperTrackEnabledForClockInOut,
  checkFacilityGeofence,
}: GeofenceStatusParams): Promise<GeofenceStatusResult> {
  let isLocationExperienceEnabled = false;
  let isOutsideFacilityGeofence = false;
  let location: Coordinates;

  if (shift.facility?.featureSettings?.radarTimekeepingValidations && shift.geofence) {
    if (isHyperTrackEnabledForClockInOut) {
      const orderHandle = `${shift._id}-${shift.agentId}`;

      isOutsideFacilityGeofence = !(await checkIsWorkerInsideFacilityGeofenceHyperTrack(
        orderHandle
      ));
      const { longitude, latitude } = await getUserCurrentLocation();
      location = longitude && latitude ? [longitude, latitude] : undefined;
      isLocationExperienceEnabled = true;

      logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK, {
        metadata: {
          isHyperTrackEnabledForClockInOut,
          shift: pick(shift, ["shiftId", "agentId", "facilityId"]),
        },
      });
    } else {
      const { isInsideGeofence, isSuccess, radarLocation } = await checkFacilityGeofence();
      isLocationExperienceEnabled = isSuccess;
      isOutsideFacilityGeofence = isSuccess && !isInsideGeofence;
      const { longitude, latitude } = radarLocation?.location || {};
      location = longitude && latitude ? [longitude, latitude] : undefined;
    }
  }

  return { isLocationExperienceEnabled, isOutsideFacilityGeofence, location };
}

async function checkIsWorkerInsideFacilityGeofenceHyperTrack(
  orderHandle: string
): Promise<boolean> {
  if (!Capacitor.isNativePlatform()) {
    logError(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED, {
      error: new Error(`Failed to fetch current order due to non-native platform.`),
      metadata: {
        orderHandle,
      },
    });

    return false;
  }

  const activeOrders = await HyperTrack.getOrders();
  const currentOrder = activeOrders.get(orderHandle);

  if (isNullOrUndefined(currentOrder)) {
    const activeOrderHandles = [...activeOrders.keys()];

    logError(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED, {
      error: new Error(`Failed to fetch current order`),
      metadata: {
        orderHandle,
        activeOrderHandles,
        numActiveOrders: activeOrderHandles.length,
      },
    });

    return false;
  }

  switch (currentOrder.isInsideGeofence.type) {
    case "success":
      logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_SUCCEEDED, {
        metadata: {
          orderHandle,
          activeOrders,
          currentOrder,
        },
      });
      return currentOrder.isInsideGeofence.value;
    case "failure":
      logError(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED, {
        error: new Error(`Failed to fetch geofence status for current order`),
        metadata: {
          orderHandle,
          activeOrders,
          currentOrder,
        },
      });
      return false;
  }
}
